import { render, staticRenderFns } from "./TextCard.vue?vue&type=template&id=70f7c196&"
import script from "./TextCard.vue?vue&type=script&lang=js&"
export * from "./TextCard.vue?vue&type=script&lang=js&"
import style0 from "@/assets/sass/components/views/dashboard/members/id/partial/cards/text-card.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports