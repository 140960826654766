<template>
  <cards-container
    class="progress-bars-card"
    :title="title"
    :date="date"
    :redirectionLink="redirectionLink"
  >
    <template v-slot:body>
      <v-row class="pr-15 pl-15 body">
        <v-col cols="12" class="pa-0 m-auto">
          <v-list-item-title class="mb-2">
            <p class="text-capitalize d-inline mb-0">{{ $t("the") }} </p>
            <p class="text-lowercase d-inline mb-0">
              {{ $t("patient") }} {{ $t("answered") }}
              {{ data.score }} {{ $t("questions") }} {{ $t("out") }}
              {{ $t("of") }} {{ data.totalScore }} {{ $t("about") }}
              {{ $t("his") }} {{ $t("treatment") }}
            </p>
          </v-list-item-title>
        </v-col>
      </v-row>
    </template>
  </cards-container>
</template>

<script>
import CardsContainer from "./CardsContainer.vue";

export default {
  name: "TextCard",

  props: {
    title: {
      type: String,
      default: "",
    },
    date: {
      type: String,
      default: "",
    },
    redirectionLink: {
      type: String,
      default: "",
    },
    data: {
      type: Object,
      default: () => {},
    },
  },

  components: {
    CardsContainer,
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/views/dashboard/members/id/partial/cards/text-card.scss">
</style>